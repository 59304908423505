<template>
    <div>
       Cerrar Sesión
    </div>
</template>
<script>
    export default {
      created () {
          localStorage.setItem('iduser',0);
              localStorage.setItem('username', '');
              localStorage.setItem('permission',0);
        this.$router.push('/')
      }
    }
</script>
<style scoped>
</style>